.Cart {
  position: absolute;
  top: 3em;
  right: 1em;
  height: 20em;
  width: 20em;
  background-color: var(--light-color);
  z-index: 1000;
  overflow: hidden;
  cursor: default;

  > div {
    position: relative;
    height: 20em;
  }

  .list {
    overflow-y: auto;
    height: 100%;
    padding-top: 1em !important;
    padding-bottom: 10em;

    .item {
      padding: 0.5em 1em;

      .content {
        width: calc(100% - 7em) !important;

        > div {
          margin-bottom: 0.5em;
        }
      }
    }
  }

  .wrapper {
    position: absolute;
    padding: 1em;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -10px 10px var(--light-mat-color);
    background-color: var(--light-color);

    .empty-cart {
      width: 100%;
      height: 80%;
    }
  }
}
