.Header {
  height: var(--nav-height);
  width: 100%;
  // border-bottom: 0.1em solid var(--dark-mat-color);
  // box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 1px 3px 1px rgb(60 64 67 / 15%);

  a {
    text-decoration: none !important;
  }

  .brand {
    display: flex;
    align-items: center;
    font-family: BowAndStern;
    word-spacing: -0.2em;

    img {
      max-height: 4em;
      padding: 0.5em;
      box-sizing: border-box;
    }

    span {
      font-size: 2em;
      font-weight: 400;
      color: var(--light-color);
    }
  }

  .layer-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: var(--light-color);
      font-size: 1.1em;
      font-weight: 800;
      padding: 0em 1em;
    }
  }

  .layer-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark-mat-color);

    > div {
      display: flex;
    }

    a {
      font-size: 1.1em;
      font-weight: 800;
      padding: 0.7em 1em;
      position: relative;
      color: var(--light-color);
      cursor: pointer;

      img {
        height: 1.5em;
        padding-right: 0.4em;
        margin-bottom: -0.35em;
      }

      @media screen and (max-width: 500px) {
        padding: 0.7em 0.5em;
      }
    }
    .name {
      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    .Label {
      position: relative;
      .label {
        position: absolute;
        top: -1.5em;
        left: -1.5rem;
      }
    }
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: unset;
  }
  @media screen and (max-width: 500px) {
    .mobile {
      display: unset;
    }
    .desktop {
      display: none;
    }
  }
}
