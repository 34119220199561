.Account {
  font-size: 1.1em;
  font-weight: 800;
  padding: 0.7em 1em;
  position: relative;
  color: var(--light-color);
  cursor: pointer;
  .username {
    display: flex;
  }

  img {
    height: 1.5em;
    padding-right: 0.4em;
    margin-bottom: -0.35em;
  }
  .menu {
    margin: 1em !important;
    min-width: calc(100% - 2em) !important;
  }

  @media screen and (max-width: 500px) {
    padding: 0.7em 0.5em;
    .name {
      display: none;
    }
    .menu {
      right: -3em !important;
      left: -6em !important;
    }
  }
}
