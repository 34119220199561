:root {
  /* Colors */
  --primary-color: #1b1212;
  --primary-hover-color: #1b1212e8;
  --primary-focus-color: #1b1212bd;
  --secondaryColor: #1b1c1d;
  --dark-color: #1b1212;
  --dark-mat-color: #28282b;
  --light-color: #ffffff;
  --light-mat-color: #c3c3c3;
  --background-primary-color: #1b1212;
  --link-color: #4183C4;
  /* Layout */
  --app-height: 100vh;
  --nav-height: 8em;
  --body-height: calc(100vh - 8em);
  /* Footer */
  --footer-background: #f5f5f7;
  --footer-text-color: #6e6e73;
  --footer-link-color: #424245;
}
