.Login {
  // background-color: #28282b;
  .wrapper {
    background-color:  var(--light-color);
    max-width: 350px;
    max-height: 400px;
    height: 100vh;
    margin: auto;
    margin-top:3em;
    padding: 2em;
    border-radius: 1em;
    z-index: 1000;
    position: relative;
    .header {
      position: relative;
      .close {
        position: absolute;
        font-size: 0.5em;
        top: -1.3em;
        right: -2em;
      }
    }
    h2 {
      margin-bottom: 2em !important;
    }
    button:not(:last-child),
    .input {
      margin-bottom: 1em;
    }
    .horizontal.divider {
      margin: 2em 0;
    }
    .ui.inverted.primary.buttons .button,
    .ui.inverted.primary.button {
      background-color: transparent;
      -webkit-box-shadow: 0px 0px 0px 2px #4183c4 inset !important;
      box-shadow: 0px 0px 0px 2px #4183c4 inset !important;
      color: #4183c4;
      &:hover {
        background-color: #4183c4;
        color:  var(--light-color);
      }
    }
  }
  .blur {
    top: 8em;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: saturate(180%) blur(30px);
    z-index: 10;
  }
}
