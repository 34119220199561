@import url(./theme/variables.css);
@import url(./theme/fonts.css);

.App {
  margin: auto;
  .body {
    padding-top: 1em;
    height: var(--body-height);
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  /* CSS specific to iOS devices */
  @supports (-webkit-touch-callout: none) {
    .body {
      height: calc(100vh - 15em);
    }
  }
}
.RedirectLogin {
  max-width: 550px;
  margin: auto;
  padding: 3em 2em;
  color: var(--light-color);
  h1 {
    font-size: 1.8rem !important;
  }
  p {
    font-size: 1.6rem;
  }
  span.link {
    cursor: pointer;
    color: var(--link-color);
  }
}
.flex {
  display: flex !important;
}

.justify-between {
  justify-content: space-between !important;
}

.align-center {
  align-items: center !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.mr-0 {
  margin-right: 0em !important;
}

.mb-0 {
  margin-bottom: 0em !important;
}

.mt-0 {
  margin-top: 0em !important;
}
